import { createSelector } from '@ngrx/store';
import { IRootState } from '../../../store';
import {
  ActionTypes,
  TypeAction
} from './background-check.actions';
import { BackGroundCheckStatusesEnum } from '../../../core/services/background-check/interfaces';

const backgroundCheckStatusesClasses = {
  [BackGroundCheckStatusesEnum.recruitPending]: 'approved',
  [BackGroundCheckStatusesEnum.pending]: 'approved',
  [BackGroundCheckStatusesEnum.success]: 'approved',
  [BackGroundCheckStatusesEnum.error]: 'error',
};

export interface IBackgroundCheckFormData {
  signature: string;
}

export interface IState {
  status: number | null;
  text: string | null;
  consent: string | null;
  formData: IBackgroundCheckFormData;
}

const initialState: IState = {
  status: null,
  text: null,
  consent: null,
  formData: {
    signature: '',
  }
};

export function backgroundCheckReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.STORE_BACKGROUND_CHECK_STATUS: {
      return Object.assign({}, state, payload);
    }
    case ActionTypes.STORE_BACKGROUND_CHECK_CONSENT: {
      return Object.assign({}, state, { consent: payload });
    }
    case ActionTypes.UPDATE_FORM_DATA: {
      const { formData } = state;
      const result = {
        ...formData,
        ...{ [payload.fieldId]: payload.value }
      };

      return Object.assign({}, state, { formData: result });
    }
    case ActionTypes.CONFIRM_CONSENT: {
      return Object.assign({}, state, payload);
    }

    default: {
      return state;
    }
  }
}

export const getBackgroundCheckStatus = createSelector(
  (root: IRootState): IState => root.backgroundCheck,
  ({ status }: IState): string => backgroundCheckStatusesClasses[status]
);

export const getBackgroundCheckText = createSelector(
  (root: IRootState): IState => root.backgroundCheck,
  ({ text }: IState): string => text
);

export const getBackgroundCheckConsent = createSelector(
  (root: IRootState): IState => root.backgroundCheck,
  ({ consent }: IState): string | null => consent
);

export const getBackgroundCheckFormData = createSelector(
  ({ backgroundCheck }: IRootState) => backgroundCheck,
  ({ formData }: IState): IBackgroundCheckFormData => formData
);
