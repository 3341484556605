export enum BackGroundCheckStatusesEnum {
  recruitPending = 0,
  pending = 1,
  success = 2,
  error = 3
}

export interface IBackgroundCheckStatusResponse {
  status: BackGroundCheckStatusesEnum;
  text: string;
}

export interface IBackgroundCheckConsentResponse {
  text: string;
}

export interface IBackgroundCheckFormData {
  signature: string;
}
