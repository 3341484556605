import { Action } from '@ngrx/store';
import { type } from '../../../util';

export const ActionTypes = {
  FETCH_BACKGROUND_CHECK_STATUS: type('[Background check] fetch Background check status'),
  STORE_BACKGROUND_CHECK_STATUS: type('[Background check] store Background check status'),
  FETCH_BACKGROUND_CHECK_CONSENT: type('[Background check] fetch Background check consent'),
  STORE_BACKGROUND_CHECK_CONSENT: type('[Background check] store Background check consent'),
  UPDATE_FORM_DATA: type('[Background check] update form data'),
  CONFIRM_CONSENT: type('[Background check] confirm consent'),
};

export class FetchBackgroundCheckStatus implements Action {
  public type = ActionTypes.FETCH_BACKGROUND_CHECK_STATUS;

  constructor(public payload?: any) {}
}

export class StoreBackgroundCheckStatus implements Action {
  public type = ActionTypes.STORE_BACKGROUND_CHECK_STATUS;

  constructor(public payload?: any) {}
}

export class FetchBackgroundCheckConsent implements Action {
  public type = ActionTypes.FETCH_BACKGROUND_CHECK_CONSENT;

  constructor(public payload?: any) {}
}

export class StoreBackgroundCheckConsent implements Action {
  public type = ActionTypes.STORE_BACKGROUND_CHECK_CONSENT;

  constructor(public payload: string) {}
}

export class FormInputUpdate implements Action {
  public type = ActionTypes.UPDATE_FORM_DATA;

  constructor(public payload: {
    fieldId: string,
    value: any
  }) {}
}

export class ConfirmBackgroundCheckConsent implements Action {
  public type = ActionTypes.CONFIRM_CONSENT;

  constructor(public payload?: any) {}
}

export type TypeAction =
  StoreBackgroundCheckStatus |
  StoreBackgroundCheckConsent |
  FormInputUpdate |
  ConfirmBackgroundCheckConsent;
