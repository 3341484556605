import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractServerInteractionService } from '../abstract/abstract.server.interaction.service';

import { Observable } from 'rxjs';
import {
  IBackgroundCheckConsentResponse,
  IBackgroundCheckStatusResponse,
  IBackgroundCheckFormData,
} from './interfaces';
import { getRawBase64Data } from '../../../util';
import { AppService } from '../../../../app.service';

@Injectable()
export class BackgroundCheckService extends AbstractServerInteractionService {
  protected get url(): {
    consentText: string,
    confirm: string,
    status: string,
  } {
    return this.appService.getApiUrls().backgroundCheck;
  }

  constructor(protected appService: AppService,
              protected http: HttpClient) {
    super();
  }

  public getBackgroundCheckStatus(): Observable<IBackgroundCheckStatusResponse | any> {
    return this.http.get(this.url.status);
  }

  public getBackgroundCheckConsent(): Observable<IBackgroundCheckConsentResponse | any> {
    return this.http.get(this.url.consentText);
  }

  public confirmConsent(formData: any) {
    return this.http.post(
      this.url.confirm,
      this.getBackgroundCheckData(formData)
    );
  }

  private getBackgroundCheckData(formData: any): IBackgroundCheckFormData {
    return {
      signature: getRawBase64Data(formData.signature),
    };
  }
}
